.poi-control {
  bottom: 30px !important;
}

.map-search {
  width: 100%;
  bottom: 0 !important;
  background-color: white;
  border-top-left-radius: 13px;
  border-top-right-radius: 13px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  padding-bottom: 15px;
  box-sizing: border-box;
}

.map-search.expanded {
  z-index: 999;
  height: 100%;
}

.search-input-container {
  display: flex;
  flex-direction: row;
  padding: 12px 8px;
  align-items: center;
  box-sizing: content-box;
}

.map-search.expanded .search-input-container {
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.25);
}

.search-input {
  border-radius: 10px;
  height: 20px;
  background-color: rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  padding: 8px;
  align-items: center;
  flex: 1;
}

.search-icon {
  display: block;
  width: auto;
  height: 100%;
  margin-right: 10px;
}

.selected-category {
  padding: 3px 8px;
  background-color: black;
  color: white;
  font-size: 17px;
  flex-shrink: 0;
  border-radius: 13px;
  align-items: center;
  margin-right: 5px;
  display: none;
}

.selected-category.active {
  display: flex;
}

.selected-category-delete {
  width: 25px;
}

.search-query-input {
  background-color: transparent;
  border: none;
  height: 100%;
  outline: none;
  flex: 1;
  font-size: 17px;
  width: 0px;
}

.map-search.expanded #search-close {
  display: block;
}

#search-close {
  height: 24px;
  width: 24px;
  margin-left: 5px;
  display: none;
  flex-shrink: 0;
}

.map-search.expanded .search-results {
  display: block;
  flex: 1;
}

.search-results {
  display: none;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.available-categories {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  padding: 5px 10px;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.25);
}

.search-category {
  width: 60px;
  margin: 5px 5px;
  word-wrap: break-word;
}

.search-category-image {
  width: 100%;
  height: auto;
}

.search-category-name {
  margin-top: 5px;
  font-size: 14px;
  text-align: center;
  width: 100%;
}

.matching-search-entities {
  padding: 0px 0px 0px 15px;
}

.search-entity {
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.25);
  padding: 10px 0px;
}

.search-entity-title {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 6px;
}

.search-entity-details {
  display: flex;
  flex-direction: row;
}

.search-entity-typeinfo {
  flex: 1;
}

.search-entity-subtitle {
  font-size: 14px;
}

.search-entity-level {
  font-size: 14px;
  color: rgb(155, 155, 155);
}

.search-entity-location {
  text-align: right;
  font-size: 14px;
  color: rgb(155, 155, 155);
  margin-left: 10px;
  margin-right: 15px;
  white-space: pre-wrap;
}
