.innerContentMap {
  padding: 0px !important;
}

.action-button {
  transition: 0.5s;
  background-color: #3f9dff;
  opacity: 0.8;
}

.action-button:active {
  cursor: grabbing;
}

.action-button:hover {
  opacity: 1;
}

.map-submit-button {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  margin: 8px;
  color: #ffffff;
  background-color: #e0e0e0;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  padding: 6px 16px;
  font-size: 0.875rem;
  min-width: 64px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  line-height: 1.75;
  border-radius: 4px;
  text-transform: uppercase;
  border: 0;
  justify-content: center;
  text-decoration: none;
}

.map-submit-button:hover {
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
    0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
}

.map-submit-button:active {
  box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%),
    0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
}

.map-submit-button.primary {
  background-color: #475bc9;
}

.map-submit-button.primary:hover {
  background-color: #303f9f;
}

.map-submit-button.success {
  background-color: #0ca600;
}

.map-submit-button.success:hover {
  background-color: #097a01;
}

.map-submit-button.danger {
  background-color: #f50057;
}

.map-submit-button.danger:hover {
  background-color: #c51162;
}

.map-submit-button:disabled {
  cursor: default;
  pointer-events: none;
  box-shadow: none;
  color: rgba(0, 0, 0, 0.26);
  background-color: rgba(0, 0, 0, 0.12);
}
